function parts(rootDir, file) {
  $.ajax({
    url: rootDir + 'includes/' + file,
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir); //html内の{$root}を置換
      document.write(html);
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.error('Error loading HTML:', textStatus, errorThrown);
    },
  });
}
